import { LOCALES } from '../locales.js';

export default {
    [LOCALES.ENGLISH]: {

        //global
        'site.title' : 'Snack on with XBOX!',
        'close': 'Close',

        //header

        //nav
        'menu.item.home' : 'HOME',
        // 'menu.item.winner' : 'PAST WINNERS',
        'menu.item.1': 'ENTER CONTEST',
        'menu.item.2': 'PRIZE DETAILS',
        'menu.item.3': 'FAQ',
        'menu.item.4': 'RULES',
        'menu.item.5': 'PARTICIPATING PRODUCTS',

        'contact-email': 'customercare@snackonwithxboxcontest.ca',

        //footer
        'footer.link1' : 'Rules',
        'footer.link2' : 'Privacy Policy (Eng)',
        'footer.link3' : 'Privacy Policy (Que)',
        'footer.link4' : 'Terms & Conditions',
        'footer.link5' : 'Accessibility Policy',
        'footer.sponsor': 'Sponsor: Mondelez Canada Inc., 277 Gladstone Avenue, Toronto, ON, M6J 3L9 ©Mondelez International group. All trademarks are property of their respective owners.{br}{br}Xbox is a registered trademark of Microsoft. Microsoft is not a sponsor.',


        'legal' : '*NO PURCHASE NECESSARY. Open to residents of Canada (age of majority). Contest begins on August 1/24 (12:00:00 a.m. ET) and closes November 15/24 (11:59:59 p.m. ET). For complete entry details, participating products and full rules visit: www.SnackOnWithXbox.ca. 25 Instant Prizes (ARV: $100 CAD each) consisting of Xbox Gift Cards; (ii) and 10 Grand Prizes consisting of one custom Xbox Series X game console. (ARV: $2500 CAD each) available to be won. Odds depend on number of eligible entries.Skill-testing question required. Trademarks are property of their respective owners. Xbox is a registered trademark of Microsoft. Microsoft is not a sponsor.',
        'legal.link' : 'www.SnackOnWithXbox.ca',

        //homapge
        'homepage.head1': 'YOU COULD',
        'homepage.head2' : '<b>WIN</b><sup>*</sup> 1<sub>OF</sub>10 CUSTOM ',
        'homepage.head3' : 'XBOX SERIES X PRIZES',
        'homepage.prizeSub' : '(ARV $2,500)',
        'homepage.pluscash': '<span>Plus instant</span>XBOX GIFT CARDS',
        'homepage.cashSub' : '(ARV $100)',
        'homepage.cta' : 'Scroll To Learn How To Enter',
        'homepage.howtoenter' : 'HOW IT WORKS',
        'homepage.step1head' : 'STEP 1',
        'homepage.step1' : 'Buy Mondelez ',
        'homepage.step1post' : '.',
        'homepage.step2head' : 'STEP 2',
        'homepage.step2' : 'Look for the 11-digit product UPC numbers** found on your participating Mondelez  product package. ***Omit first numbers to left of the code.',
        'homepage.step3head' : 'STEP 3',
        'homepage.step3' : 'Enter UPCs for a chance to win* 1 of 10 Custom Xbox Series X consoles plus Instant XBOX gift card prizes.',

        'contestClosedHead' : 'CONTEST CLOSED',
        'contestClosedText' : 'Thanks For Entering!',
        // 'contestClosedLink' : 'https://howcaramilkismade.com/',
        // 'contestClosedCTA' : 'click here',

        'enterNow' : 'ENTER',

         //coming soon and home page
        'comingsoon.head1' : "GET READY TO",
        'comingsoon.head2' : "START YOUR ENGINES AND",
        'comingsoon.head3' : "SNACK ON WITH XBOX!",
        'comingsoon' : 'COMING SOON – AUGUST\u00A01<sup>ST</sup>,\u00A02024',


        //enter contest page
        'step1': 'Step 1',
        'enterPin': 'ENTER  UPC',
        'enterKeyDescription': 'Enter the 11-digit product UPC numbers found on your participating Mondelez',
        'enterKeyLink': 'Product package',
        'enterKeySmall': '**Omit first number to the left of the code.',
        'popupBodytext' : 'Look for the UPC code on the back of your purchase of a ',
        'popupBodylink' : 'participating product',
        'popupBodylinkAlt' : 'participating products',
        'pin': 'UPC CODE',
        'nextStep': 'NEXT\u00a0STEP',

        'step2': 'Step 2',
        'enterInfo': 'ENTRY INFORMATION',
        'enterInfoDescription': 'Provide your contest entry information to enter and to see if you have won an Instant Prize!',
        'firstName': 'First Name',
        'lastName': 'Last Name',
        'emailAddress': 'Email Address',
        'phone': 'Phone',
        'infoForm.age': 'I confirm that I am of the age of majority in the province/territory where I reside*',
        'infoForm.rules' : 'I have read and confirm compliance with the ',
            'infoForm.rules.link1' : 'Contest rules and regulations',
            'infoForm.rules2' : ' and the ',
            'infoForm.rules.link2' : 'Mondelez Privacy Policy',
            'infoForm.rules3' : '*',
        'infoForm.optin' : 'I confirm my consent to Mondelez Canada Inc. storing, sharing and using my personal information submitted for the purpose of administering the Contest (including notifying me if I am selected as an eligible winner of a prize) in accordance with the Contest Rules and Regulations and the Mondelez Privacy Policy*',
        'submit': 'SUBMIT',
        'required': '* Required fields',



        //winning page
        'congratulation' : 'ALMOST THERE!',
        'winningPage.heading' : 'You’re a Potential Winner of a $100 XBOX Gift Card!',
        'winningPage.body' : 'Before being declared an official winner, you must correctly answer, unaided, the mathematical skill-testing question below.',
        // 'winningPage.body2': 'Plus, you have been entered into the draw for the Grand Prize!',
        'winningPage.question' : '(6X6) – 16, then add 5 =',
        'winningPage.answer' : "Your Answer",
        'winningPage.cta' : 'SUBMIT',
        'winningPage.legal' : 'Please retain your PIN code /wrapper as the Contest Sponsor may require production of the winning PIN code before awarding a prize.',

        'winningPage.heading1' : 'CONGRATULATIONS!',
        'winningPage.heading2' : 'You’re a Potential Winner of a $100 XBOX Gift Card!',
        'winningPage.body2' : 'Our customer care team will be in direct contact with you within 24 hours to confirm you’re an official WINNER!',
        'winningPage.body2a' : '.{br}{br}Please make sure that the following address is marked as safe in your email settings: customercare@snackonwithxboxcontest.ca{br}{br}Plus, you have been entered into the draw for the Grand Prize!',

        'winningPage.heading3' : 'SORRY, INCORRECT ANSWER.',
        'winningPage.body3' : 'Please try again tomorrow.',

        // 'winningPage.heading2' : 'SHARE THE NEWS',
        'download': 'Download',


        //losing page
        'tryAgain' : 'THANKS FOR ENTERING!',
        'tryAgain.body' : 'You have successfully been entered into the next draw for a chance to WIN*{br}{br}You are not an instant winner but there are still Xbox Gift Card prizes to be won.{br}{br}Try again tomorrow with another UPC found on participating packs for another chance to win.',
        // 'tryAgain.bodyb' : ' for another chance to win.',
        // 'tryAgain.btn' : 'TRY AGAIN', 
        'tryAgain.heading2' : 'SHARE THE FUN',
        'tryAgain.body2' : 'Get your crew in on the action! Share the contest with your friends and let them know there are still XBOX prizes to be won!',
        'tryAgain.body3' : 'Want to share to Instagram? Click the download button below and receive an image you can upload to your Instagram account.',

        //Prize Details
        'prizeDetails' : 'PRIZE DETAILS',

        'prizeDetails-heading1' : 'GRAND PRIZE',
        'prizeDetails-body1' : 'Experience the Xbox Series X, the fastest, most powerful Xbox ever. You can play thousands of titles from four generations of consoles, but this prize comes with an exclusive, limited edition Forza Motorsport skin design to make you feel even more immersed in the driving action.{br}{br}',
        'prizeDetails-body2a': '*Please refer to ',
        'prizeDetails-body2b': ' for full details*',
        'rulesLink': 'contest rules',

        'prizeDetails-heading2' : 'INSTANT WIN',
        // 'prizeDetails-subheading2' : 'Custom Xbox Series S consoles',
        'prizeDetails-body2' : 'With an $100 Xbox Gift Card, you have the freedom to pick the gift you want. It can be used to buy the hottest new Xbox full game downloads, apps, films, TV programmes, devices and more. There are no fees or expiry dates to worry about. Valid for purchases at Microsoft Store online, on Windows and on Xbox.',

        // Participating Products
        'products' : 'PARTICIPATING PRODUCTS',
        
        'products.group2.title' : 'Crispers',
        'products.group2' : `CHR CRISPERS CHEDDAR 145G 12{br}
        CHRISTIE CRISPERS CHEDDAR 145 G 12{br}
        CRISPERS ALL DRESSED BULK 50G 84{br}
        CRISPERS ASSAISONNÉS VRAC 50 G 84{br}
        CRISPERS DILL PICKLE 145G 12{br}
        CRISPERS CORNICHONS À L'ANETH 145 G{br}
        CRISPERS BBQ 145G 12{br}
        CRISPERS BARBECUE 145 G 12{br}
        CRISPERS RANCH 145G 12{br}
        CRISPERS ALL DRESSED RRP 50G 3X14{br}
        CRISPERS ASSAISONNÉS 50 G 3X14{br}
        CRISPERS BITS & BITES ORIG 145G 12{br}
        CRISPERS MÉLI-MÉLO ORIGINAL 145G 12{br}
        CRISPERS BITS & BITES CHEESE 145G 12{br}
        CRISPERS MÉLI-MÉLO FROMAGE 145G 12{br}
        CRISPERS BITS & BITES BBQ 145G 12{br}
        CRISPERS MÉLI-MÉLO BBQ 145G 12{br}
        CRISPERS JALAPENO 145G 12{br}
        CRISPERS SMOKEY BACON 145G 12{br}
        CRISPERS BACON FUMÉ 145 G 12`,

        'products.group3.title' : 'Cadbury',
        'products.group3' : `CARAMILK 50G 6X48{br}
        CARAMILK 50G 6X48 GOLD{br}
        CARAMILK 50G 6X48 DOREE{br}
        CARAMILK 50G 6X48 GP{br}
        PAL FL CARAMILK SGL 50GR 8640CT{br}
        PAL PLEIN CARAMILK REG 50GR 8640UN{br}
        CARAMILK 50G 8X24{br}
        CARAMILK 50G 8X24 GOLD{br}
        CARAMILK 50G 8X24 DOREE{br}
        CARAMILK 50G 8X24 GP{br}
        CARAMILK 50G 6X48{br}
        WUNDERBAR 58GR 8X36{br}
        PPD CARAMILK SGL CTR 96CT{br}
        PREEMB COMPT CARAMILK REG 96UN{br}
        PPD CARAMILK SGL FLR 192CT{br}
        PREEMB PLANC CARAMILK REG 192UN{br}
        PPD CARAMILK SGL PDQ 270CT{br}
        PREEMB PDR CARAMILK REG 270UN{br}
        DRP HALF CARAMILK SGL 1728CT{br}
        DRP DEMI CARAMILK REG 1728UN{br}
        PPD CARAMILK SGL CTR 96CT{br}
        PREEMB COMPT CARAMILK REG 96UN{br}
        CRUNCHIE KING SIZE 66G 6X24{br}
        CRUNCHIE FORM SUPER 66G 6X24 32P{br}
        MR BIG ORIGINAL 4PK 240G 10{br}
        MR BIG ORIGINALE PQT DE 4 240 G 10{br}
        CARAMILK SLTD CARAMEL 50G 8X24{br}
        CARAMILK CARAMEL SALÉ 50G 8X24{br}
        CARAMILK SLTD CARAMEL 50G 8X24 GP{br}
        CARAMILK CARAMEL SALÉ 50G 8X24 GP{br}
        CRISPY CRUNCH 48G 12X24{br}
        WUNDERBAR 58G 12X24{br}
        WUNDERBAR 58G 12X24 XB{br}
        MR BIG ORIGINAL 60G 8X24{br}
        MR BIG ORIGINALE 60G 8X24{br}
        MR BIG ORIGINAL 60G 8X24{br}
        MR BIG ORIGINAL 60G 8X24 XB{br}
        MR BIG ORIGINALE 60G 8X24 XB{br}
        MR BIG ORIGINAL 60G 8X24 XB{br}
        MR BIG ORIGINAL 60G 8X24 XB{br}
        MR BIG ORIGINALE 60G 8X24 XB{br}
        MR BIG ORIGINAL 60G 8X24 XB{br}
        PAL FL MR BIG 60G 4032CT{br}
        PAL PLEIN MR BIG EC 60GR 4032UN{br}
        WUNDERBAR CP 58GR 12X24{br}
        WUNDERBAR EC 58GR 12X24{br}
        CRUNCHIE CP 44GR 12X24{br}
        CRUNCHIE EC 44GR 12X24{br}
        CRUNCHIE 44G 12X24{br}
        MR BIG KING SIZE 90G 6X24{br}
        MR BIG FORMAT SUPER 90G 6X24{br}
        WUNDERBAR KING SIZE 90G 6X24{br}
        WUNDERBAR FORMAT SUPER 90G 6X24{br}
        CARAMILK KING SIZE 78G 6X24{br}
        CARAMILK FORMAT SUPER 78G 6X24{br}
        CARAMILK KING SIZE 78G 6X24 GOLD{br}
        CARAMILK FORMAT GÉANT 78 G 6X24{br}
        PPD MR BIG ORIGINAL CTR 96CT{br}
        PREEMB COMPT MR BIG ORIGINALE 96UN{br}
        PPD MR BIG ORIGINAL PDQ 132CT{br}
        PREEMB PDR MR BIG ORIGINALE 132 UN{br}
        PPD MR BIG DEAL CTR 96CT{br}
        PPD COMPT MR BIG DEAL 96UN{br}
        PPD MR BIG DEAL PDQ 132CT{br}
        PPD PDR MR BIG DEAL 132UN`,

        'products.group1.title' : 'Maynards/Sour Patch Kids',
        'products.group1' : `SOUR PATCH KIDS BIG HEADZ 154G 12{br}
        SOUR PATCH KIDS BERRIES 150G 12{br}
        SOUR PATCH KIDS BAIES 150 G 12{br}
        SPK WATERMELON 154G 12{br}
        SPK MELON D'EAU 154G 12{br}
        SOUR PATCH KIDS 150G 12{br}
        SOUR PATCH KIDS STRAWBERRY 154G 12{br}
        SOUR PATCH KIDS FRAISE 154 G 12{br}
        SOUR PATCH KIDS FUN MIX 315G 12{br}
        SPK RÉGALS-ÉCLAIR 315G 12{br}
        SPK STRAWBERRY 154G 2X9{br}
        SPK FRAISE 154G 2X9{br}
        SPK SR CHRY BLASTERS 154G 8{br}
        SOUR PATCH KIDS BIG HEADZ 154G 8{br}
        SOUR PATCH KIDS BERRIES 150G 8{br}
        SOUR PATCH KIDS BAIES 150 G 8{br}
        SPK WATERMELON 154G 8{br}
        SPK MELON D'EAU 154G 8{br}
        SOUR PATCH KIDS 150G 8{br}
        SOUR PATCH KIDS STRAWBERRY 154G 8{br}
        SOUR PATCH KIDS FRAISE 154 G 8{br}
        MAYNARDS FUZZY PEACH 154G 8{br}
        MAYNARDS WINE GUMS 154G 8{br}
        MAYNARDS SWEDISH BERRIES 154G 8{br}
        MAYNARDS SWEDISH BERRIES 154G 8{br}
        MAYNARDS SWEDBRS & CREME 154G 8{br}
        MAYNARDS SWEDBRS & CREME 154G 8{br}
        SOUR PATCH KIDS GRAPE 154G 8{br}
        SOUR PATCH KIDS RAISIN 154G 8{br}
        SOUR PATCH KIDS GRAPE 154G 8{br}
        SOUR PATCH KIDS RAISIN 154G 8{br}
        MAYNARDS ORIGINAL GUMMIES 150G 8{br}
        SPK SR CHRY BLASTERS 154G 12{br}
        MAYNARDS SWEDBRS & CREME 154G 12{br}
        MAYNARDS SWEDBRS & CREME 154G 12{br}
        MAYNARDS WINE GUMS 154G 12{br}
        MAYNARDS FUZZY PEACH 154G 12{br}
        SOUR PATCH KIDS GRAPE 154G 12{br}
        SOUR PATCH KIDS RAISIN 154G 12{br}
        SOUR PATCH KIDS GRAPE 154G 12{br}
        SOUR PATCH KIDS RAISIN 154G 12{br}
        MAYNARDS ORIGINAL GUMMIES 150G 12{br}
        SOUR PATCH KIDS 315G 12{br}
        SPK WATERMELON 315G 12{br}
        SPK MELON D'EAU 315G 12{br}
        SPK SRCHRY BLASTERS 308G 12{br}
        MAYNARDS SWEDBRY TROPICAL 315G 12{br}
        MAYNARDS SWEDISH BERRIES 315G 12{br}
        MAYNARDS FUZZY PEACH 308G 12{br}
        MAYNARDS SWEDISH BERRIES 154G 12{br}
        SOUR PATCH KIDS LEMONADE 150G 12{br}
        SOUR PATCH KIDS LIMONADE 150 G 12{br}
        SOUR PATCH KIDS LEMONADE 150G 8{br}
        SOUR PATCH KIDS LIMONADE 150G 8{br}
        SOUR PATCH KIDS BLUE 150G 12{br}
        SOUR PATCH KIDS BLEU 150 G 12{br}
        MAYNARDS SOUR PATCH KIDS 185G 12{br}
        MAYNARDS SOUR PATCH KIDS 185G 12 XB{br}
        MAYNARDS WINE GUM ROLLS 44G 12X18{br}
        MAYNARDS WINE GUMS 315G 12{br}
        MAYNRD SPK SRCHRY BLASTER 64G 12X18{br}
        MAYNARDS FUZZY PEACH 64G 12X18{br}
        MAYNARDS SWEDISH BERRIES 64G 12X18{br}
        MAYNARDS ORIG GUMMIES 60G 12X18{br}
        MAYNARDS SOUR PATCH KIDS 60G 12X18{br}
        MAYNARDS SOUR PATCH KIDS 185G 2X9{br}
        MAYNARDS SOUR PATCH KIDS 185G 2X9 XB{br}
        MAYNARDS FUZZY PEACH BOX 100G 12{br}
        MAYNARDS FUZZY PEACH BOITE 100G 12{br}
        MAYNRD SOUR WATERMELON BOX 100G 12{br}
        MAYNRD SOUR WATERMELON BTE 100G 12{br}
        MAYNRD SWEDISH BERRIES BOX 100G 12{br}
        MAYNRD SWEDISH BERRIES BTE 100G 12{br}
        MAYNARDS SPK EXTREME BOX 100G 12{br}
        MAYNARDS SPK EXTREME BOITE 100G 12{br}
        MAYNARDS WINE GUMS DRC 315G 10{br}
        MAYNRD WINE GUMS BTE PRES 315G 10{br}
        SOUR PATCH KIDS EXTREME 150G 12`,
        

        //faq page
        'faq-heading-text1': 'While the ',
            'faq-heading-link': 'Official Rules',
        'faq-heading-text2': ' outline the complete details of this promotion, your questions may already be answered below.',

        'q1': 'Q1. What is the date period for this promotion?',
        'a1': 'A. Contest starts August 01 and ends November 15, 2024.',
        
        'q2': 'Q2. What are the prizes for this promotion?',
        'a2': 'A. Prizes available at contest start:{br}{br}There will be ten (10) grand prizes available to be won. Each consisting 10 Grand Prizes consisting of one custom Xbox Series X game console.  (ARV: $2500 CAD each). {br}{br}There will also be twenty-five (25) Instant Prizes consisting of Xbox Gift Cards.  (ARV: $100 CAD each).',
        
        'q2-b' : 'Q3. How do I enter without purchasing a product?',
        'a2-b' : 'A. To obtain a UPC Code without purchasing an Eligible Product – send an email to',
        'a2-c' : 'during the Promotion Period that includes: (i) the Re: line <b>“Snack On With Xbox – No Purchase Entry Request”</b>; (ii) your first name, last name, telephone number and age; and (iii) a fifty (50) word or more unique and original sentence on <b>Which Forza Motor Sport vehicle  is your favourite and why?</b> (each a “Request”). Upon receipt of a valid a Request in accordance with these Official Rules, Administrator will e-mail you a randomly selected UPC Code.',
        
        'q3': 'Q4. How many times can I enter?',
        'a3': 'A. There is a limit of one (1) Entry per person, per day.',
        
        'q4': 'Q5. How many prizes could I win? ',
        'a4': 'A. There is a limit of one (1) Grand Prize per person. Plus, a limit of 1 INSTANT WIN prize per person during the promotion.',

        'q5': 'Q6. I have a valid UPC’s, but I am getting an error.',
        'a5': 'A. Please enter the code(s) without any dashes or spaces and refer to our ',
        'a5a': 'participating products',
        'a5b': ' line up to make sure your purchase is eligible. If it is still not working, we are here to help!   {br}{br}Please contact us at: ',

        // 'q6': 'Q. I have a valid PIN code, but I’m getting an error.',
        // 'a6': 'A. Please enter the 12-digit alpha PIN code without any dashes or spaces. If it’s still not working, we’re here to help! Please contact us at ',
        
        'q6': 'Q7. I received a message that I have already entered today, but I have not. What should I do?',
        'a6': 'A. Make sure you are visiting ',
            'a6-link': 'www.snackonwithxbox.ca',
        'a6-bottom': ' directly rather than a bookmarked page in your browser. Also, please try to clear cookies and cache on your internet browser.',

        'q7': 'Q8. I am having technical issues. What should I do?',
        'a7': 'A. Sometimes it is a simple thing like updating your browser. Check to be sure that you have a modern version of Chrome, Internet Explorer, Firefox, or Safari. Also, you may want to check your browser preferences section to be sure JavaScript is enabled. Clearing your cache and cookies also helps in this instance.',

        'q8': 'Q9. How will I know if I am a potential an INSTANT WIN Prize Winner?',
        'a8': 'A. You will be advised on the screen if you have been declared a potential Instant Win prize. You will then be contacted directly via email by our customer care team via customercare@snackonwithxboxcontest.ca to complete the declaration & release form, including correctly answering a mathematical skill testing question before being declared an official winner.',
        
        // 'q9': 'Q. I am having trouble receiving my PC Game Pass Email, what should I do?',
        // 'a9': 'Please check your email and spam filters or contact us at ',
        
        // 'q10': 'Q. I am having trouble getting my PC Game Pass to work? What should I do?',
        // 'a10': 'For details on how to redeem a Code, visit: ',
        // 'a10-link': 'https://support.xbox.com/en-CA/help/subscriptions-billing/redeem-codes-gifting/redeem-prepaid-codes',

        'q11': 'Q10. I have recently updated my email address. Can I change my registration information for this promotion?',
        'a11': 'A. We apologize for any inconvenience, but due to the number of entries we receive, we are unable to change registration information. If you are entered into the Grand Prize Draw during the promotional period and your contact information does change, contact ',
        'a11-2' : ' and provide your updated information.  {br}{br} Please note: If your contact information changes during the promotional period, we cannot guarantee that we will be able to accommodate your request. {br}{br} Still have a question? Please contact us at ',
        'a11-3' : ' Please note: a response may take up to three (3) business days.',

        //Rules page
        'rules-page-heading': 'OFFICIAL RULES',
    }
}